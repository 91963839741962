<template>
  <div>
    <div 
      style="border: 2px solid red;" 
      :style="{width: `${mainWidth}px`, height: `${mainHeight}px`}"
      id="design"
      v-if="zones"
    >
      <div
        v-for="(item, index) of zones"
        :key="index"
        style="position:stiky;border: 1px solid black;"
        :style="{
          marginTop: '${item.newTop}px',
          marginRight: '${item.newRight}px',
          width: '${item.newWidth}px',
          height: '${item.newHeight}px',
        }"
      >
        {{item.ID}}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'TemplateStruct',
  props: {
    template: {
      type: Object,
      default: null,
    },
    mainWidth: {
      type: Number,
      default: 500,
    },
  },
  data: () => ({
    size: null,
    scale: null,
    mainHeight: 1080,
    zones: null,
  }),
  watch: {
    'template.ID' () {
      this.prepareZones()
    },
  },
  mounted () {
    this.scale = this.mainWidth / 1920
    this.prepareZones ()
  },
  methods: {
    prepareZones () {
      this.mainWidth = 1920 * this.scale
      this.mainHeight = 1080 * this.scale
      if (!this.scale) return
      this.zones = this.template.Zones.map(item => {
        item.newTop = (item.Top * this.scale) | 0
        item.newRight = (item.Right * this.scale) | 0
        item.newWidth = (item.Width * this.scale) | 0
        item.newHeight = (item.Height * this.scale) | 0
        return item
      })
    },
  },
}
</script>

